import React, { Component } from "react";
import { db } from '../firebase';
import {
  Textbox,
  Textarea
} from "react-inputs-validation";

import nodemailer from 'nodemailer';


class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validate: false,

      hasNameError: true,
      hasEmailError: true,
      hasDescriptionError: true

    }
  };
  updateState = (e) => {
    this.setState({ [e.target.id]: e.target.value })

  }
  componentDidMount = () => {

  }



  handleComplete = (c_name, c_email, c_phone, c_description) => {


    let transporter = nodemailer.createTransport({
      service: 'gmail',
      auth: {
        user: 'u1.earthboundadventures@gmail.com',
        pass: 'Evanston60202'
      }
    });

    let gomail, mailOptions, getDeliveryStatus;

    if (!c_phone) {
      c_phone = "";
    }
    let c_subject = this.props.Subject + " trip information request";

    const {
      hasNameError,
      hasDescriptionError,
      hasEmailError
    } = this.state;

    if (
      !hasNameError &&
      !hasDescriptionError &&
      !hasEmailError
    ) {




      const contactFormID = db.ref("/contactform").push();
      contactFormID
        .set(
          {
            contactform_subject: c_subject,
            contactform_name: c_name,
            contactform_email: c_email,
            contactform_phone: c_phone,
            contactform_description: c_description,
            contactform_pagename: c_name.toString().toLowerCase().replace(/\s/g, '-'), //lowercase and no space will be helpful for URLs
            contactform_created_at: Date.now(),
            contactform_viewed: false,

          },
          function (error) {
            if (error) {
              alert("Sorry unable to submit your request. Please try again." + error);
            } else {
              alert("Request submitted successfully. Thank you!");

              

            }
          }
        )
    } else {
      this.setState({ validate: true });
    }





  }



  render() {
    return (
      <React.Fragment>
        <div className="container page-content">
          <div className="row">
            <div className="col-12 mb-2 text-center">
              <h5 className="content-titles">{this.props.Title}</h5>
            </div>
            <div className="col-12">



              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_name} id="tg_name" placeholder="Enter Trip Group Name" 
                  onChange={this.updateState} /> */}
                <Textbox
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "c_name",
                    name: "Full Name",
                    type: "text",
                    placeholder: "Name"
                  }}
                  value={this.state.c_name} // Optional.[String].Default: "".
                  disabled={false} // Optional.[Bool].Default: false.
                  validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                  validationCallback={res =>
                    this.setState({ hasNameError: res, validate: false })
                  } // Optional.[Func].Default: none. Return the validation result.
                  classNameInput="form-control" // Optional.[String].Default: "".
                  classNameWrapper="" // Optional.[String].Default: "".
                  classNameContainer="" // Optional.[String].Default: "".
                  customStyleInput={{}} // Optional.[Object].Default: {}.
                  customStyleWrapper={{}} // Optional.[Object].Default: {}.
                  customStyleContainer={{}} // Optional.[Object].Default: {}.
                  onChange={(c_name, e) => {
                    this.setState({ c_name });
                  }} // Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => {
                  }}
                  validationOption={{
                    name: "Contact Name", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                    required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                  }}
                />
              </div>
              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_name} id="tg_name" placeholder="Enter Trip Group Name" 
                  onChange={this.updateState} /> */}
                <Textbox
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "c_email",
                    name: "Email",
                    type: "text",
                    placeholder: "Email"
                  }}
                  value={this.state.c_email} // Optional.[String].Default: "".
                  disabled={false} // Optional.[Bool].Default: false.
                  validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                  validationCallback={res =>
                    this.setState({ hasEmailError: res, validate: false })
                  } // Optional.[Func].Default: none. Return the validation result.
                  classNameInput="form-control" // Optional.[String].Default: "".
                  classNameWrapper="" // Optional.[String].Default: "".
                  classNameContainer="" // Optional.[String].Default: "".
                  customStyleInput={{}} // Optional.[Object].Default: {}.
                  customStyleWrapper={{}} // Optional.[Object].Default: {}.
                  customStyleContainer={{}} // Optional.[Object].Default: {}.
                  onChange={(c_email, e) => {
                    this.setState({ c_email });
                  }} // Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => {
                  }}
                  validationOption={{
                    name: "Contact Email", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                    required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                  }}
                />
              </div>
              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_name} id="tg_name" placeholder="Enter Trip Group Name" 
                  onChange={this.updateState} /> */}
                <Textbox
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "c_phone",
                    name: "Phone Number",
                    type: "text",
                    placeholder: "Phone Number (Optional)"
                  }}
                  value={this.state.c_phone} // Optional.[String].Default: "".
                  disabled={false} // Optional.[Bool].Default: false.
                  classNameInput="form-control" // Optional.[String].Default: "".
                  classNameWrapper="" // Optional.[String].Default: "".
                  classNameContainer="" // Optional.[String].Default: "".
                  customStyleInput={{}} // Optional.[Object].Default: {}.
                  customStyleWrapper={{}} // Optional.[Object].Default: {}.
                  customStyleContainer={{}} // Optional.[Object].Default: {}.
                  onChange={(c_phone, e) => {
                    this.setState({ c_phone });
                  }} // Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => {
                  }}
                  validationOption={{
                    name: "Contact Phone", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                    check: false, // Optional.[Bool].Default: true. To determin if you need to validate.
                    required: false // Optional.[Bool].Default: true. To determin if it is a required field.
                  }}
                />
              </div>
              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_name} id="tg_name" placeholder="Enter Trip Group Name" 
                  onChange={this.updateState} /> */}
                <Textarea
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "c_description",
                    name: "Message",
                    type: "text",
                    placeholder: "Message"
                  }}
                  value={this.state.c_description} // Optional.[String].Default: "".
                  disabled={false} // Optional.[Bool].Default: false.
                  validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                  validationCallback={res =>
                    this.setState({ hasDescriptionError: res, validate: false })
                  } // Optional.[Func].Default: none. Return the validation result.
                  classNameInput="form-control" // Optional.[String].Default: "".
                  classNameWrapper="" // Optional.[String].Default: "".
                  classNameContainer="" // Optional.[String].Default: "".
                  customStyleInput={{}} // Optional.[Object].Default: {}.
                  customStyleWrapper={{}} // Optional.[Object].Default: {}.
                  customStyleContainer={{}} // Optional.[Object].Default: {}.
                  onChange={(c_description, e) => {
                    this.setState({ c_description });
                  }} // Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => {
                  }}
                  validationOption={{
                    name: "Contact Message", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                    required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                  }}
                />
              </div>


              <div className="col-12 text-center">
                <button onClick={() => this.handleComplete(this.state.c_name, this.state.c_email, this.state.c_phone, this.state.c_description)} className="btn btn-primary btn-submit">Submit</button>
              </div>
            </div>

          </div>
        </div>





      </React.Fragment>
    );
  }
};

export default Contact;