import React, { Component } from "react";
import WhyUs from './whyus';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount = () => {
    document.title = "About Us | Earthbound Adventures";
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div className="container">
            <div className="row my-3">
              <div className="col-12 mb-3">
                <h4 className="content-titles text-center">
                  About Us
                </h4>
                <p className="mt-2 text-muted text-justify">
                  Founded in 1994, Earthbound Adventures is a pioneer in eco-tourism and a 
                  leading adventure-travel company offering unique treks and tours to 
                  South America. Whether you dream of swimming with sea lions in the 
                  Galapagos or drifting through the Amazon rain forest on a moonlit 
                  canoe trip, Dancing Samba In Rio De Janeiro, Tango in Buenos Aires, 
                  or hiking the Inca Trail, you will be fully immersed in this breathtaking
                  landscape among diverse cultures. Carefully crafted tours led by local 
                  naturalists ensure a tour respectful of the environment. We purposely 
                  operate on a smaller level with personal attentions.<br/><br/>
                  
                  Unlike other adventure travel groups, we limit the number of trips to each
                  destination, traveling in groups of only six to a dozen people to minimize
                  our impact on the fragile surroundings. Because the preservation of our planet
                  is of utmost importance, we support international conservation organizations.
                  We also donate money to plant 30 trees for each person who travels with us to
                  offset the carbon footprint inherent in travel. <br/><br/>
                  
                  All of our tours are based on our own personal adventures over many years,
                  so you're traveling to places we know intimately. Our carefully chosen guides
                  are South Americans who have first-hand knowledge of your destination. 
                  So, while you are benefiting from a native's perspective, we are reinvesting 
                  in the local economy.<br/><br/>
                  
                  Standing out among the first environmentally responsible travel companies, 
                  a 1995 U.S. News & World Report article cited Earthbound Adventures as one of 
                  the best eco-tour companies in the U.S. We invite and encourage you to join us 
                  and see the difference!
                </p>
              </div>
              <div className="mb-2 col-12 text-center">
                <h4 className="content-titles">Our Team</h4>
              </div>
              <div className="col-12">
                <div className="mb-3 d-flex flex-column flex-md-row team-row">
                  <img src="https://firebasestorage.googleapis.com/v0/b/earthbound-adventures.appspot.com/o/images%2F248583e8-9c66-493c-a1ec-7ff0f5a513d4.jpg?alt=media&token=65d225c7-d4e4-4e39-b13d-54a184745f3a"
                    className="align-self-center mr-3 team-img" alt="Tony tour leader" />
                  <div className="media-body">
                    <h5 className="mt-0 mb-0">Tony </h5>
                    <small className="text-muted"><i>Tour Leader</i></small>
                    <p className="mt-2 text-justify">Unlike other tour directors who have spent days, weeks or, at most, months traveling to the destinations they offer clients, Tony  has literally spent years hiking the far corners of South America and other continents. He knows these regions and cultures intimately, as only an intrepid backpacker can.<br />
                      In 1982, he embarked on a childhood dream of exploring and living among the people of South America. For the next four years he backpacked the length and breadth of the continent, from climbing Aconcangua to exploring the remote Bolivian jungle. His travels have taken him into the heart of Central America, the far reaches of Alaska and Canada,
                        and the countries of South East Asia. His remarkable adventures lasted seven years and continue to this day.</p>
                  </div>
                </div>

                <div className="mb-3 d-flex flex-column flex-md-row team-row">
                  <img src="https://firebasestorage.googleapis.com/v0/b/earthbound-adventures.appspot.com/o/images%2F9911dcec-07da-4b5e-a782-1ed9eee9c4a1.jpg?alt=media&token=ec536a9b-4d11-4b20-88a4-7525c3317814"
                    className="align-self-center mr-3 team-img" alt="Carmen Tour Guide" />
                  <div className="media-body">
                    <h5 className="mt-0 mb-0">Carmen Margot Coello Luna </h5>
                    <small className="text-muted"><i>Tour Guide</i></small>
                    <p className="mt-2 text-justify">Margot was a tour guide for eight years on the Inca Trail to Machu Picchu
                      and alternative trails, as well as Cusco and the Sacred Valley. Holding a degree
                      in administrative sciences and tourism from the National University of San Antonio Abad,
                       Margot is fluent in Spanish, English, and Quechua. “I enjoy trekking myself,” she says,
                       “so I enjoy introducing other people to this wonderful way of enjoying our beautiful country.</p>
                  </div>
                </div>
                <div className=" mb-3 d-flex flex-column flex-md-row team-row">
                  <img src="https://firebasestorage.googleapis.com/v0/b/earthbound-adventures.appspot.com/o/images%2F96069f99-980e-4c95-a9eb-4a01280fee9e.jpg?alt=media&token=3d1c1b56-5a82-4424-afc2-b6f4e4714665"
                    className="align-self-center mr-3 team-img" alt="Ivanov Trekking Guide" />
                  <div className="media-body">
                    <h5 className="mt-0 mb-0">Ivanov Hinojosa Echegaray</h5>
                    <small className="text-muted"><i>Trekking Guide</i></small>
                    <p className="mt-2 text-justify">Born in Cusco, Ivanov holds a degree in administrative sciences and tourism
                      from the University of San Antonio Abad Del Cusco. Ivanov is fluent in Spanish,
                      English, and Quechua. He is very familiar with the Cusco region, having guided
                      treks for 10 years. “After working with other travel agencies,
                      I joined Earthbound Adventures because I could see that their tours are different
                      and travelers go home with a lifetime of memories.”</p>
                  </div>
                </div>

              </div>
              <div className="col-12">
                <WhyUs />
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
};

export default About;