import React, { Component } from "react";
import { db, fbStorage } from '../firebase';
import FileUploader from "react-firebase-file-uploader";
import {
    Textbox,
    Textarea
  } from "react-inputs-validation";


class AddTripGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
     validate: false,
     hasGroupDescriptionError : true,
     hasGroupNameError: true,
     hasGroupImageError: true
    }
  };
  updateState = (e) => {
    this.setState({ [e.target.id] : e.target.value  })
}
  componentDidMount = () => {
   
  }


  validateForm(e) {
    e.preventDefault();
    this.toggleValidating(true);
    const {
      // name,
      // job,
      // country,
      // agreement,
      // description,
      hasNameError,
      hasDescriptionError,
      hasMovieError,
      hasJobError,
      hasAgreementError
    } = this.state;
    if (
      !hasNameError &&
      !hasDescriptionError &&
      !hasMovieError &&
      !hasJobError &&
      !hasAgreementError
    ) {
      alert("All validated!");
    }
  }

  handleComplete = (tg_name, tg_description, tg_image) => {
    
    const {
      hasGroupNameError,
      hasGroupDescriptionError,
      hasGroupImageError
    } = this.state;
    if (
      !hasGroupDescriptionError &&
      !hasGroupNameError &&
      !hasGroupImageError
    ) {
      const projectID = db.ref("/tripgroup").push();
      projectID
        .set(
          {
            tripgroup_name: tg_name,
            tripgroup_description: tg_description,
            tripgroup_image: tg_image,
            tripgroup_pagename: tg_name.toString().toLowerCase().replace(/\s/g, '-'), //lowercase and no space will be helpful for URLs
            tripgroup_created_at: Date.now()
          },
          function (error) {
            if (error) {
              alert("Data could not be saved." + error);
            } else {
              alert("Data saved successfully.");
              window.location.reload();
            }
          }
        )
    } else {
      this.setState({ validate: true });
    }

  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    alert(error);
  };

  handleUploadSuccess = filename => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    fbStorage.ref("images").child(filename).getDownloadURL().then(url => this.setState({ avatarURL: url, tg_image : url }));
  };


  render() {
    return (
      <React.Fragment>
        <div className="container page-content">
          <div className="row">
            <div className="col-12">
              <h2>Add New Trip Group</h2>
            </div>
            <div className="col-12">
              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_name} id="tg_name" placeholder="Enter Trip Group Name" 
                  onChange={this.updateState} /> */}
                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "tg_name",
                      name: "Trip Group Name",
                      type: "text",
                      placeholder: "Enter Trip Group Name"
                    }}
                    value={this.state.tg_name} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res =>
                      this.setState({ hasGroupNameError: res, validate: false })
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(tg_name, e) => {
                      this.setState({ tg_name });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Trip Group Name", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />
              </div>
             

              <div className="form-group">
                {/* <textarea type="text" className="form-control" value={this.state.tg_description} id="tg_description" placeholder="Describe the Trip Group" 
                  onChange={this.updateState} /> */}
                            <Textarea
                                attributesWrapper={{}}
                                attributesInput={{
                                    id: "tg_description",
                                    name: "Trip Group Description",
                                    placeholder: "Enter Trip Group Description"
                                }}
                                value={this.state.tg_description} // Optional.[String].Default: "".
                                disabled={false} // Optional.[Bool].Default: false.
                                validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                                validationCallback={res =>
                                    this.setState({ hasGroupDescriptionError: res, validate: false })
                                } // Optional.[Func].Default: none. Return the validation result.
                                classNameInput="form-control" // Optional.[String].Default: "".
                                classNameWrapper="" // Optional.[String].Default: "".
                                classNameContainer="" // Optional.[String].Default: "".
                                customStyleInput={{}} // Optional.[Object].Default: {}.
                                customStyleWrapper={{}} // Optional.[Object].Default: {}.
                                customStyleContainer={{}} // Optional.[Object].Default: {}.
                                onChange={(tg_description, e) => {
                                    this.setState({ tg_description });
                                }} // Required.[Func].Default: () => {}. Will return the value.
                                onBlur={e => {
                                }}
                                validationOption={{
                                    name: "Trip Group  Description", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                                    required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                                }}
                            />



              </div>

             

              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_image} id="tg_image" placeholder="Project Cover Image url " 
                  onChange={this.updateState} /> */}

                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "tg_image",
                      name: "Trip Group Image",
                      type: "text",
                      placeholder: "Image URL Please upload image below"
                    }}
                    value={this.state.tg_image} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res =>
                      this.setState({ hasGroupImageError: res, validate: false })
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(tg_image, e) => {
                      this.setState({ tg_image });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Trip Group Image", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />


              </div>
              

    
              <button onClick={() => this.handleComplete(this.state.tg_name, this.state.tg_description, this.state.tg_image)} className="btn btn-primary btn-submit">Submit</button>

            </div>
            <div className="col-12 mt-5">
              <h5>Upload images here</h5>

              {this.state.isUploading &&
              
                <div className="progress-bar" role="progressbar" style={{ width: this.state.progress + '%' }} aria-valuenow={this.state.progress} aria-valuemin="0" aria-valuemax="100">{this.state.progress}</div>
              
              }
              {this.state.avatarURL &&
                <div className="uploaded image">
                  <label>Image:</label>
                  <a rel="noopener noreferrer" target="_blank" href={this.state.avatarURL}><img alt="new" className="img img-fluid" src={this.state.avatarURL} /></a>
                </div>
              }
              <FileUploader
                accept="image/*"
                name="avatar"
                randomizeFilename
                storageRef={fbStorage.ref("images")}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              />
            </div>
          </div>
        </div>




            
      </React.Fragment>
    );
  }
};

export default AddTripGroup;