import React, { Component } from "react";
import { Like, Achievement, Favorite } from "grommet-icons";
import { Link } from 'react-router-dom';

class WhyUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
    
    }
  };



  render() {
    return (
      <React.Fragment>
        <div className="row">
            <div className="col-12 mt-5 mb-3 text-center">
                <h4 className="content-titles mb-4">WHY CHOOSE US? </h4>
                <div className="row mt-5">
                  <div className="col-md-4 mb-4 text-center">
                    <div className="card h-100">
                      <div className="card-body text-center">
                        <div className="card-title"> <Like className="why-us-icon" color="black"></Like></div>
                        <h6 className="card-text">HIGHLY QUALIFIED SERVICE</h6>
                        <p className="text-muted">Talk to support team from anywhere in the world, any hour of day.</p>
                      </div>
                    </div>

                 
                  </div>
                  <div className="col-md-4 mb-4 text-center">
                  <div className="card h-100">
                      <div className="card-body text-center">
                        <div className="card-title">  <Achievement className="why-us-icon" color="black"></Achievement></div>
                        <h6 className="card-text">HANDPICKED HOTELS</h6>
                        <p className="text-muted">We always pick the best for our customers.</p>
                      </div>
                    </div>
                 
                  </div>
                  <div className="col-md-4 mb-4 text-center">
                  <div className="card h-100">
                      <div className="card-body text-center">
                        <div className="card-title">  <Favorite className="why-us-icon" color="black"></Favorite></div>
                        <h6 className="card-text">BEST PRICE GUARANTEE</h6>
                        <p className="text-muted">If you can find lower price, we'll refund you 100% of the difference</p>
                      </div>
                    </div>
                  
                  </div>
                  <div className="col-12 text-center">
              <Link to="/contact" className="btn btn-primary btn-submit text-white">Contact Us</Link>
              </div>
                </div>
              </div>
            </div>
           
      </React.Fragment>
    );
  }
};

export default WhyUs;