import React, {Component}  from "react";
import { fbStorage} from '../firebase';

class WebPdfs extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
           pdfs: ''
        }
        this.updateState = this.updateState.bind(this);
     };
     updateState(e) {
        this.setState({s_name: this.refs.s_name.value, s_description: this.refs.s_description.value});
     }

    componentDidMount = () => {
        let temp = [];
        let tempLocation = [];
        fbStorage.ref("pdf").listAll().then((res) => {
            res.items.map((images) => {
                images.getDownloadURL().then(url => {
                   tempLocation[tempLocation.length] = images.location.path_;
                   temp[temp.length] = url;
                   this.setState({pdfs: temp});
                   this.setState({pdfLocation: tempLocation});
                })
            })
        })
    }

    deleteImage = (key) => {
        let imageL =  this.state.pdfLocation;
        if (window.confirm('Are you sure you want to delete?')) 
        {
            fbStorage.ref().child(imageL[key]).delete().then(function() {
                // File deleted successfully
                alert("successfully deleted !!")
                window.location.reload();
              }).catch(function(error) {
                // Uh-oh, an error occurred!
                alert(error.message)
              });
        }
    }

    

  render() {
    return (
    <React.Fragment>
            <div className="container page-content">
                <h2>Pdfs Click to view</h2>
                <div className="row images-div">
                   
                   {this.state.pdfs && this.state.pdfs.map((url,key) => {
                       return(
                         
                           <div className="col-6 col-md-4 mt-2 text-center">
                              <a href={url} target="_blank" rel="noopener noreferrer" className="btn btn-success text-white webpdf-view">VIEW PDF</a>
                              <button className="btn btn-danger ml-2 webpdf-delete" onClick={() => {this.deleteImage(key)}}>X</button>
                           </div>
                       )
                   }
                     )}
                        
                </div>
            </div>

      </React.Fragment>
    );
  }
};

export default WebPdfs;