import React, { Component } from "react";
import { db } from '../firebase';
import { Link } from 'react-router-dom';

class TripGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidUpdate(prevProps) {
    // will be true
    const locationChanged =
      this.props.location !== prevProps.location;
      if(locationChanged)
      {
        let tripGroupPageName = this.props.match.params.tripgroup;
        this.getTripGroupDetailsByPageName(tripGroupPageName)
        
      }
     
    // INCORRECT, will *always* be false because history is mutable.
    // const locationChanged =
    //   this.props.history.location !== prevProps.history.location;
  }
  componentDidMount = () => {
    let tripGroupPageName = this.props.match.params.tripgroup;
    this.getTripGroupDetailsByPageName(tripGroupPageName)
    
  }

  getTripGroupDetailsByPageName = (pageName) => {
    //Search function firebase realtime database this way of getting data is efficient.
    let tripGroupId;
    let tripGroupsRef = db.ref("/tripgroup");
    tripGroupsRef.orderByChild("tripgroup_pagename").equalTo(pageName).once("child_added", snapshot => {
      this.setState({ tripGroupDetails: snapshot.val() });
      tripGroupId = snapshot.key;
      document.title = "Trips to "+ snapshot.val()["tripgroup_name"] + " | Earthbound Adventures";
      if (tripGroupId) {
        this.getAllTripsByGroupId(tripGroupId);
      }
    })
  }

  getAllTripsByGroupId = (tripgroupid) => {
    let tripsRef = db.ref("/trip");
    tripsRef.orderByChild("trip_tripgroupid").equalTo(tripgroupid).once("value", snapshot => {
      if (snapshot) {
        this.setState({ tlist: snapshot });
      }
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="">
          {this.state.tripGroupDetails &&
            <div className="tripgroup-details">
              <div className=" tripgroup-header">
                <div className="tripgroup-banner" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),url(${this.state.tripGroupDetails["tripgroup_image"]})` }}>
                  <div className="container">
                    <h2 className="tripgroup-title text-center">{this.state.tripGroupDetails["tripgroup_name"]}</h2>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12 text-justify">
                    <p className="tripgroup-description">{this.state.tripGroupDetails["tripgroup_description"]}</p>
                  </div>
                </div>
              </div>
            </div>
          }
          <div className="container tripgroup-trips mt-4">
            <div className="row">
              {this.state.tlist &&
                Object.keys(this.state.tlist.val()).map(id => {
                  let t = this.state.tlist.val();
                  return (
                    <div className="col-md-6  mb-4" key={id}>
                      <Link className="no-text-decoration" to={{ pathname: `/trip/${t[id]["trip_pagename"]}`, tripid: `${id}` }}>
                        <div className="tripgroup-trip-card">
                          <div style={{ backgroundImage: `url(${t[id]["trip_image"]})` }} className="tripgroup-trip-image"></div>
                          <div className="tripgroup-trip-short-description">
                            <div className="tripgroup-trip-border-top"></div>
                            <div className="tripgroup-trip-description text-center">
                              <h5 className="tripgroup-trip-description-title mb-0"> {t[id]["trip_name"]}</h5>
                              <div className="mt-3">
                              <small className="d-inline-block tripgroup-trip-description-price float-left">Starts at {t[id]["trip_priceperperson"]} per person</small>

                              <small className="d-inline-block tripgroup-trip-description-price float-right">Difficulty : {t[id]["trip_difficulty"]}</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default TripGroup;