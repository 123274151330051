import React, { Component } from "react";
import Contact from './contact';
import { Map, Phone} from "grommet-icons";

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = () => {
       document.title = "Contact US | We are here to help you"
    }



    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12" style={{padding:'0'}}>
                        <iframe title="office location" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11851.200906532538!2d-87.687466!3d42.047464!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf54eae6b347dc62a!2sEarthbound%20Adventures!5e0!3m2!1sen!2sus!4v1573176533431!5m2!1sen!2sus" style={{width:'100%',height:'250px',border:'0'}} frameBorder="0"  allowFullScreen=""></iframe>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <div className="page-content">
                            <h5 className="content-titles">We Would Love To Hear From You</h5>
                            <p>Our team is happy to answer your questions. Fill out the form and we'll be in touch
                                as soon as possible. Thank You!
                            </p>
                            <div className="mb-2">
                                <Map color="black" /> <a rel="noopener noreferrer" className="ml-2 text-dark" target="_blank" href="https://www.google.com/maps/place/Earthbound+Adventures/@42.047464,-87.687466,13z/data=!4m5!3m4!1s0x0:0xf54eae6b347dc62a!8m2!3d42.047464!4d-87.687466?hl=en" >1101 Davis St #390, Evanston, IL 60202</a>
                            </div>
                            <div className="mt-3 mb-2">
                                <Phone color="black" /><a className="ml-2" href="tel:18004547554"> 1.800.454.7554</a>
                            </div>

                            </div>
                            
                        </div>
                        <div className="col-md-6 mt-3">
                            <Contact Subject="Contact Us Page" Title="How can we contact you?" />
                        </div>

                    </div></div>

            </React.Fragment>
        );
    }
};

export default ContactUs;