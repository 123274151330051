import React, {Component}  from "react";
import {Link} from 'react-router-dom';


class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
     }
  render() {
    return (
      <React.Fragment>

        <div className="container page-content">
          <div className="row">
            <div className="col-md-6 mb-3">
              <Link className="no-text-decoration" to="/addtripgroup">
                <div className="card dashboard-card h-100">
                  <div className="card-body">
                    <h5 className="card-title service-title"> Add New Trip Group</h5>
                    <p className="card-text service-card-description text-justify"> Add new trip group, and image url in the form is the cover image
                                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 mb-3">
              <Link className="no-text-decoration" to="/edittripgroup">
                <div className="card dashboard-card h-100">
                  <div className="card-body">
                    <h5 className="card-title service-title"> Edit / Delete Trip Group  </h5>
                    <p className="card-text service-card-description text-justify"> You can edit or delete a particular Trip Group. 
                    If you delete a trip group you will not be able to see all the trips under the group. </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 mb-3">
              <Link className="no-text-decoration" to="/addtrip">
                <div className="card dashboard-card h-100">
                  <div className="card-body">
                    <h5 className="card-title service-title"> Add New Trip  </h5>
                    <p className="card-text service-card-description text-justify"> Add new trip and select the trip group. </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 mb-3">
              <Link className="no-text-decoration" to="/edittrip">
                <div className="card dashboard-card h-100">
                  <div className="card-body">
                    <h5 className="card-title service-title"> Edit / Delete Trip  </h5>
                    <p className="card-text service-card-description text-justify"> You can edit or delete a particular trip. </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 mb-3">
              <Link className="no-text-decoration" to="/addtripimages">
                <div className="card dashboard-card h-100">
                  <div className="card-body">
                    <h5 className="card-title service-title"> Add Trip Images  </h5>
                    <p className="card-text service-card-description text-justify"> Add Images to a particular trip to display in the gallery. </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 mb-3">
              <Link className="no-text-decoration" to="/webimages">
                <div className="card dashboard-card h-100">
                  <div className="card-body">
                    <h5 className="card-title service-title"> Web Images  </h5>
                    <p className="card-text service-card-description text-justify"> View all the Images on the website </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 mb-3">
              <Link className="no-text-decoration" to="/webpdfs">
                <div className="card dashboard-card h-100">
                  <div className="card-body">
                    <h5 className="card-title service-title"> Web Pdfs  </h5>
                    <p className="card-text service-card-description text-justify"> View all the pdfs on the website </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 mb-3">
              <Link className="no-text-decoration" to="/deletetripimages">
                <div className="card dashboard-card h-100">
                  <div className="card-body">
                    <h5 className="card-title service-title"> Delete Trip Images  </h5>
                    <p className="card-text service-card-description text-justify"> View all the pdfs on the website </p>
                  </div>
                </div>
              </Link>
            </div>

          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default Admin;