import React from "react";
import { Link } from "react-router-dom";
import { FacebookOption, Instagram, Phone } from "grommet-icons";


const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer mt-5">
        <div className="container">
          <div className="row ">
            <div className="col-8">
              <Link to="" className="no-text-decoration footer-text"> EARTHBOUND ADVENTURES</Link>
            </div>
            <div className="col-4 text-right my-auto">
              <a href="tel:18004547554"><Phone className="social-icon" color="white"></Phone></a>
              <Link to="/"><FacebookOption className="social-icon mx-3" color="white"></FacebookOption></Link>
              <Link to="/"><Instagram className="social-icon" color="white"></Instagram></Link>
            </div>
            <div className="col-12">
              
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;