import React, { Component } from "react";
import { db, fbStorage } from '../firebase';
import FileUploader from "react-firebase-file-uploader";
import {
    Textbox
  } from "react-inputs-validation";


class AddTripImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
     validate: false,

    hasImageError: true,
    
    }
  };
  updateState = (e) => {
    this.setState({ [e.target.id] : e.target.value  })
    if(e.target.id === "i_tripid")
    {
        if(e.target.value === "empty")
        {
            this.setState({triperror: true})
        }
        else{
            this.setState({triperror: false})
        }
        
    }
    

}
  componentDidMount = () => {
   /** BIND SERVICES DROPDOWN WITH AVAILABLE SERVICE */
   const t_ref = db.ref("/trip");
   t_ref.once("value", snapshot => {
     if (snapshot.val()) {
       this.setState({ tlist: snapshot.val() })
       
     }
   })

   
  }



  handleComplete = (i_tripid,i_tripimage,i_tripimagecaption) => {

    if(i_tripid ==="empty" || !i_tripid)
    {
        this.setState({triperror:true});
    }
    if(!i_tripimagecaption)
    {
      i_tripimagecaption = "";
    }
    const {
        hasImageError,
      } = this.state;
    
      if (
        !hasImageError && i_tripid !== "empty" && i_tripid
      ) {
        const projectID = db.ref("/tripimages").push();
        projectID
          .set(
            {
              
              image_tripid:i_tripid,
              image_tripimage:i_tripimage,
              image_tripimagecaption:i_tripimagecaption,
              image_created_at: Date.now()
              
            },
            function (error) {
              if (error) {
                alert("Data could not be saved." + error);
              } else {
                alert("Data saved successfully.");
                window.location.reload();
              }
            }
          )
      }else{
          this.setState({validate: true});
      }


    
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => {
    this.setState({ progress: progress });
  }

  handleUploadError = error => {
    this.setState({ isUploading: false });
    alert(error);
  };

  handleUploadSuccess = filename => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    fbStorage.ref("images").child(filename).getDownloadURL().then(url => this.setState({ avatarURL: url, i_tripimage : url }));
  };



  render() {
    return (
      <React.Fragment>
        <div className="container page-content">
          <div className="row">
            <div className="col-12">
              <h2>Add New Trip Image</h2>
            </div>
            <div className="col-12">
            <div className="form-group">

                <select className="form-control" value={this.state.i_tripid} id="i_tripid"
                  onChange={this.updateState}>
                    <option value="empty">SELECT A TRIP</option>
                  {this.state.tlist &&
                    Object.keys(this.state.tlist).map(id => {
                      let t = this.state.tlist;
                      return (
                        <option key={id} value={id}>{t[id]["trip_name"]}</option>
                      )
                    })
                  }
                </select>
                {this.state.triperror  && (<small className="form-text text-danger" >Please select a Trip</small>) }
              </div>

              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_image} id="tg_image" placeholder="Project Cover Image url " 
                  onChange={this.updateState} /> */}

                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "i_tripimage",
                      name: "Trip Image",
                      type: "text",
                      placeholder: "Image URL Please upload image below"
                    }}
                    value={this.state.i_tripimage} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res =>
                      this.setState({ hasImageError: res, validate: false })
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(i_tripimage, e) => {
                      this.setState({ i_tripimage });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Trip Image", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />


              </div>


              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_image} id="tg_image" placeholder="Project Cover Image url " 
                  onChange={this.updateState} /> */}

                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "i_tripimagecaption",
                      name: "Image Caption",
                      type: "text",
                      placeholder: "Image Caption (Optional)"
                    }}
                    value={this.state.i_tripimagecaption} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={false} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(i_tripimagecaption, e) => {
                      this.setState({ i_tripimagecaption });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Trip Image Caption", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: false, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: false // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />


              </div>
              
              <button onClick={() => this.handleComplete(this.state.i_tripid,this.state.i_tripimage,this.state.i_tripimagecaption)} className="btn btn-primary btn-submit">Submit</button>

            </div>
            <div className="col-12 mt-5">
              <h5>Upload images here</h5>

              {this.state.isUploading &&
              
                <div className="progress-bar" role="progressbar" style={{ width: this.state.progress + '%' }} aria-valuenow={this.state.progress} aria-valuemin="0" aria-valuemax="100">{this.state.progress}</div>
              
              }
              {this.state.avatarURL &&
                <div className="uploaded image">
                  <label>Image:</label>
                  <a target="_blank" rel="noopener  noreferrer" href={this.state.avatarURL}><img alt="new" className="img img-fluid" src={this.state.avatarURL} /></a>
                </div>
              }
              <FileUploader
                accept="image/*"
                name="avatar"
                randomizeFilename
                storageRef={fbStorage.ref("images")}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              />
            </div>

            
          </div>
        </div>




            
      </React.Fragment>
    );
  }
};

export default AddTripImages;