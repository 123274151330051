import * as firebase from 'firebase';

const config = {
    apiKey: "AIzaSyABE5N2q3BBKm-0QtMwydiRWGZRqJHZU6I",
    authDomain: "earthbound-adventures.firebaseapp.com",
    databaseURL: "https://earthbound-adventures.firebaseio.com",
    projectId: "earthbound-adventures",
    storageBucket: "earthbound-adventures.appspot.com",
    messagingSenderId: "19010992894",
    appId: "1:19010992894:web:0eb1be1cc26c907ec6c3af",
    measurementId: "G-5VB2JMC7M6"
  };
  firebase.initializeApp(config);
  
  export const db = firebase.database(); 
  export const fbStorage = firebase.storage();
  export const fbAuth = firebase.auth();