import React, { Component } from "react";
import { db } from '../firebase';
import Contact from './contact';
import { History, Star, Money, Accessibility } from "grommet-icons";
import GalleryU from "./gallery";

class Trip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ilist: false
    }
  }
  componentDidUpdate(prevProps) {
    // will be true
    const locationChanged =
      this.props.location !== prevProps.location;
      if(locationChanged)
      {
        let trippagename = this.props.match.params.trip;
        this.getTripDetailsByPageName(trippagename);
      }
     
    // INCORRECT, will *always* be false because history is mutable.
    // const locationChanged =
    //   this.props.history.location !== prevProps.history.location;
  }

  componentDidMount = () => {
    let trippagename = this.props.match.params.trip;
    this.getTripDetailsByPageName(trippagename);
  }

  getImagesByTrip = (tripid) => {
    let list, photosList = [];
    let tripsRef = db.ref("/tripimages");
    tripsRef.orderByChild("image_tripid").equalTo(tripid).once("value", snapshot => {
      if (snapshot.val()) {
        list = snapshot.val();
        Object.keys(list).map((id, index) => {
          photosList.push({ 'src': list[id]["image_tripimage"] });
        })
        this.setState({ photosList: photosList, ilist: true });
      } else {
        this.setState({ ilist: false })
      }
    })
  }

  getTripDetailsByPageName = (pageName) => {
    //Search function firebase realtime database this way of getting data is efficient.
    let tripRef = db.ref("/trip");
    tripRef.orderByChild("trip_pagename").equalTo(pageName).once("child_added", snapshot => {
      document.title = snapshot.val()["trip_name"];
      this.setState({ tripDetails: snapshot.val() });
      this.getImagesByTrip(snapshot.key);
    })
  }


  render() {
    return (
      <React.Fragment>
        <div className="">
          {this.state.tripDetails &&
            <div className="tripgroup-details">
              <div className=" tripgroup-header">
                <div className="tripgroup-banner" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),url(${this.state.tripDetails["trip_image"]})` }}>
                  <div className="container">
                    <h2 className="tripgroup-title text-center">{this.state.tripDetails["trip_name"]}</h2>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12 text-justify">
                    <p className="tripgroup-description">{this.state.tripDetails["trip_description"]}</p>
                  </div>
                  <div className="col-12 my-3">
                    <ul className="list-group list-group-horizontal-sm">
                      <li className="list-group-item trip-detail-list-item text-md-center">
                        <span><History className="social-icon" color="black"></History><small className="text-muted mx-2">Trip Duration: </small><b> {this.state.tripDetails["trip_duration"]} Days</b> </span>
                      </li>
                      <li className="list-group-item trip-detail-list-item text-md-center">
                        <span><Star /><small className="text-muted mx-2">Best During: </small><b> {this.state.tripDetails["trip_besttimetotravel"]}</b> </span>
                      </li>
                      <li className="list-group-item trip-detail-list-item text-md-center">
                        <span><Money /><small className="text-muted mx-2">Per Person: </small><b> {this.state.tripDetails["trip_priceperperson"]}</b> </span>
                      </li>
                      <li className="list-group-item trip-detail-list-item text-md-center">
                        <span><Accessibility /><small className="text-muted mx-2">Difficulty: </small><b> {this.state.tripDetails["trip_difficulty"]}</b> </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 my-2 text-center">
                    <a target="_blank" rel="noopener  noreferrer" href={this.state.tripDetails["trip_itinerary"]} className="btn btn-primary btn-submit text-white">View Itinerary</a>
                  </div>
                  <div className="col-12 my-2">
                    {this.state.ilist ? (
                      <GalleryU images={this.state.photosList} />
                    ) : null}
                  </div>
                  <div className="col-12">
                    <Contact Subject={this.state.tripDetails["trip_name"]} Title="Request More Information" />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>

      </React.Fragment>
    );
  }
};

export default Trip;