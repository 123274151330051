import React,{Component} from 'react';
import { Route, Redirect, Switch, Link,useParams, useLocation } from "react-router-dom";
import './App.css';
import { fbAuth } from "./firebase";
import { connect } from "react-redux";
import { UPDATE_USER, SIGN_OUT, UPDATE_LOCATION } from "./js/actions/index";
import TripGroups from "./components/tripgroups";
import TripGroup from "./components/tripgroup";
import Trip from "./components/trip";
import IndexPage from "./components/index";
import NavBar from "./components/navbar";
import Footer from './components/footer';
import PrivateRoute from "./components/privateroute";
import Admin from "./components/admin";
import Login from "./components/login";
import AddTripGroup from "./components/addtripgroup";
import EditTripGroup from "./components/edittripgroup";
import Email from './components/email';
import EmailDetails from './components/emaildetails';
import { Home,MailOption } from "grommet-icons";
import AddTrip from './components/addtrip';
import EditTrip from './components/edittrip';
import WebPdfs from './components/webpdfs';
import WebImages from './components/webimages';
import AddTripImages from './components/addtripimages';
import DeleteTripImages from './components/imagesbytrip';
import  ContactUs from "./components/contactus";
import About from './components/aboutus';
import ScrollToTop from './components/scrolltotop'

const mapStateToProps = state => {
  return state;
}

const mapDispatchToProps = dispatch => {
  return {
    UPDATE_USER: (user) => {
      dispatch(UPDATE_USER(user))
    },
    SIGN_OUT: () => {
      dispatch(SIGN_OUT())
    },
    UPDATE_LOCATION: (loc) => {
      dispatch(UPDATE_LOCATION(loc))
    }
  };
}



class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      authenticated: false,
      user: null
    }
  };

  componentDidMount() {
    
  

    fbAuth.onAuthStateChanged(user => {
      if (user) {
        this.props.UPDATE_USER(user);
      } else {
        this.setState({
          authenticated: false,
          currentUser: null,
          loading: false
        });
      }
    });
   

   this.props.UPDATE_LOCATION("test");
  }

  handleSignOut = () => {
    fbAuth.signOut().then(() => {
      this.props.SIGN_OUT();
    }).catch(function (error) {
      // An error happened.
    });
  }

  render() {
    

    return (
      <div className="main-container">
        <NavBar />
        <main className=" home-content">
          <ScrollToTop/>
          <Switch>
            <Route path="/eb-login" component={Login} />
            <Route exact path="/trips" component={TripGroups} />
            <Route exact path="/trips/:tripgroup" component={TripGroup} />
            <Route path="/trip/:trip" component={Trip} />
            <Route path="/index" component={IndexPage} />
            <Route path="/contact" component={ContactUs} />
            <Route exact path="/about-us" component={About} />
            <PrivateRoute exact path="/eb-admin" component={Admin} authenticated={this.props.authenticated} />
            <PrivateRoute exact path="/addtripgroup" component={AddTripGroup} authenticated={this.props.authenticated} />
            <PrivateRoute exact path="/edittripgroup" component={EditTripGroup} authenticated={this.props.authenticated} />
            <PrivateRoute exact path="/addtrip" component={AddTrip} authenticated={this.props.authenticated} />
            <PrivateRoute exact path="/edittrip" component={EditTrip} authenticated={this.props.authenticated} />
            <PrivateRoute exact path="/webpdfs" component={WebPdfs} authenticated={this.props.authenticated} />
            <PrivateRoute exact path="/webimages" component={WebImages} authenticated={this.props.authenticated} />
            <PrivateRoute exact path="/eb-emails" component={Email} authenticated={this.props.authenticated} />
            <PrivateRoute exact path="/eb-email/:email" component={EmailDetails} authenticated={this.props.authenticated} />
            <PrivateRoute exact path="/addtripimages" component={AddTripImages} authenticated={this.props.authenticated} />
            <PrivateRoute exact path="/deletetripimages" component={DeleteTripImages} authenticated={this.props.authenticated} />
            {/* <PrivateRoute exact path="/addprojectimages" component={AddProjectImages} authenticated={this.props.authenticated} />
            <PrivateRoute exact path="/addproject" component={AddProject} authenticated={this.props.authenticated} />
            
            <PrivateRoute exact path="/editservice" component={EditService} authenticated={this.props.authenticated} />
            <PrivateRoute exact path="/editproject" component={EditProject} authenticated={this.props.authenticated} /> */}
            <Redirect from="/" exact to="/index" component={IndexPage} />
            
          </Switch>
        </main>

        {this.props.authenticated &&
          <div className="container mt-5">
            <div className="row">
              <div className="col-12">
            <div className="card">
              <div className="card-header">
              {this.props.currentUser}
              </div>
              <div className="card-body">
               <div className="row">
                 <div className="col-6">
                 <Link className="btn admin-button" to="/eb-admin" ><Home className="admin-icon" color="black"></Home> <br/> HOME</Link>
                 </div>
                 <div className="col-6">
                 <Link className="btn admin-button" to="/eb-emails" ><MailOption className="admin-icon" color="black"></MailOption> <br/> EMAIL</Link>
                 </div>
               </div>
              </div>
              <div className="card-footer text-muted " style={{background:"transparent"}}>
              <input type="button" className="btn btn-danger btn-submit mx-4" value="SIGN OUT" onClick={() => this.handleSignOut()} />
              </div>
            </div>
            </div></div>
          </div>
        }
        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

