import React, {Component}  from "react";
import {db, fbStorage} from '../firebase';
import FileUploader from "react-firebase-file-uploader";
import {
    Textbox,
    Textarea
  } from "react-inputs-validation";

class EditTripGroup extends Component {
  
    constructor(props) {
        super(props);
        
        this.state = {
            validate: false,

            hasNameError: false,
            hasDescriptionError: false,
            hasImageError: false,
            hasPricePerPersonError: false,
            hasDurationError: false,
            hasDifficultyError: false,
            hasStartLocationError: false,
            hasEndLocationError: false,
            hasAccomidationError: false,
            hasBestTimeToTravelError: false,
            hasActivitiesError: false,
            hasPDFError: false,
            tripgrouperror:false
        }
     };
     

     componentDidMount = () => {
     

        /* Bind service to the dropdown*/
        const t_ref = db.ref("/trip");
        t_ref.once("value", snapshot => {
           if(snapshot){

            this.setState({tlist :snapshot})
           }
        })


        const tg_ref = db.ref("/tripgroup");
   tg_ref.once("value", snapshot => {
     if (snapshot.val()) {
       this.setState({ tglist: snapshot.val() })
       
     }
   })
        
     }

   handleComplete = (t_tripid,t_name, t_description, t_image,t_pdf,t_priceperperson,t_duration,t_difficulty,t_startlocation,t_endlocation,t_accomidation,t_besttimetotravel,t_activities,t_tripgroupid) => {
        
      /* Update the changes made to the trip group */
      const {
        hasNameError,
        hasDescriptionError,
        hasImageError,
        hasPricePerPersonError,
        hasDurationError,
        hasDifficultyError,
        hasStartLocationError,
        hasEndLocationError,
        hasAccomidationError,
        hasBestTimeToTravelError,
        hasActivitiesError,
        hasPDFError,
      } = this.state;
    
      if (
        !hasNameError &&
        !hasDescriptionError &&
        !hasImageError &&
        !hasPricePerPersonError &&
        !hasDurationError &&
        !hasDifficultyError &&
        !hasStartLocationError &&
        !hasEndLocationError &&
        !hasAccomidationError &&
        !hasBestTimeToTravelError &&
        !hasPDFError&&
        !hasActivitiesError && t_tripgroupid !== "empty" && t_tripgroupid
      ) {
      db.ref("/trip/"+t_tripid).update(
           {
             
            trip_name:t_name,
            trip_description:t_description,
            trip_image:t_image,
            trip_itinerary:t_pdf,
            trip_accomidation:t_accomidation,
            trip_priceperperson:t_priceperperson,
            trip_duration: t_duration,
            trip_difficulty: t_difficulty,
            trip_startlocation:t_startlocation,
            trip_endlocation:t_endlocation,
            trip_besttimetotravel:t_besttimetotravel,
            trip_Activities:t_activities,
            trip_pagename: t_name.toString().toLowerCase().replace(/\s/g, '-'), //lowercase and no space will be helpful for URLs
            trip_created_at: Date.now(),
            trip_tripgroupid:t_tripgroupid
           },
           function(error) {
             if (error) {
               alert("Data could not be saved." + error);
             } else {
               alert("Data saved successfully.");
               window.location.reload();
             }
           }
         )
        }else{
            this.setState({validate: true});
        }
        
      }

      

      handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

      handleProgress = progress => this.setState({ progress : progress});

      handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
      };

      handleUploadSuccess = filename => {
        this.setState({ avatar: filename, progress: 100, isUploading: false });
        fbStorage.ref("images").child(filename).getDownloadURL().then(url => this.setState({ avatarURL: url, tg_image: url }));
      };



      tripSelected = (e) => {
        if(e.target.value !== "empty")
        {
          this.setState({t_tripid: e.target.value})
          const t_ref = db.ref("/trip/"+e.target.value);
            t_ref.once("value", snapshot => {
                if (snapshot) {
                    console.log(snapshot)
                  this.setState({
                      t_name: snapshot.val()["trip_name"], 
                      t_description: snapshot.val()["trip_description"], 
                      t_image: snapshot.val()["trip_image"],
                      t_pdf: snapshot.val()["trip_itinerary"],
                      t_accomidation:snapshot.val()["trip_accomidation"],
                      t_priceperperson:snapshot.val()["trip_priceperperson"],
                      t_duration: snapshot.val()["trip_duration"],
                      t_difficulty: snapshot.val()["trip_difficulty"],
                      t_startlocation:snapshot.val()["trip_startlocation"],
                      t_endlocation:snapshot.val()["trip_endlocation"],
                      t_besttimetotravel:snapshot.val()["trip_besttimetotravel"],
                      t_activities:snapshot.val()["trip_Activities"],
                      t_tripgroupid:snapshot.val()["trip_tripgroupid"]
                    })
                }
            })
        }
      }

      deleteTripGroup = (id) => {
       
          
          if (window.confirm('Are you sure you want to delete?')) 
          {
              db.ref("/trip/").child(id).remove().then(function() {
                  // File deleted successfully
                  alert("successfully deleted !!")
                  window.location.reload();
                }).catch(function(error) {
                  // Uh-oh, an error occurred!
                  alert(error.message)
                });
          }
          
    
      }

      showAllTripGroups = (e) => {
        this.setState({showalltripgroups: e.target.checked})
      }
      
      updateState = (e) => {
          this.setState({ [e.target.id] : e.target.value  })
      }

  render() {
    return (
    <React.Fragment>
            <div className="container page-content">
                <div className="row">
                    <div className="col-12">
                        <h2>Edit Trip</h2>
                    </div>

                    <div className="col-12 my-5">
                        <div className="form-group">

                            <select className="form-control" id="t_tripid" 
                                onChange={this.tripSelected} value={this.state.t_tripid}>
                                  <option value="empty">SELECT A TRIP</option>
                                {this.state.tlist &&
                                    Object.keys(this.state.tlist.val()).map(id => {
                                        let t = this.state.tlist.val();
                                        return (
                                            <option value={id}>{t[id]["trip_name"]}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                    </div>

                    <div className="col-12">
                       
              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_name} id="tg_name" placeholder="Enter Trip Group Name" 
                  onChange={this.updateState} /> */}
                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "t_name",
                      name: "Trip Name",
                      type: "text",
                      placeholder: "Enter Trip Name"
                    }}
                    value={this.state.t_name} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res =>
                      this.setState({ hasNameError: res, validate: false })
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(t_name, e) => {
                      this.setState({ t_name });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Trip Name", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />
              </div>
             

              <div className="form-group">
                {/* <textarea type="text" className="form-control" value={this.state.tg_description} id="tg_description" placeholder="Describe the Trip Group" 
                  onChange={this.updateState} /> */}
                            <Textarea
                                attributesWrapper={{}}
                                attributesInput={{
                                    id: "t_description",
                                    name: "Trip Description",
                                    placeholder: "Enter Trip Description"
                                }}
                                value={this.state.t_description} // Optional.[String].Default: "".
                                disabled={false} // Optional.[Bool].Default: false.
                                validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                                validationCallback={res =>
                                    this.setState({ hasDescriptionError: res, validate: false })
                                } // Optional.[Func].Default: none. Return the validation result.
                                classNameInput="form-control" // Optional.[String].Default: "".
                                classNameWrapper="" // Optional.[String].Default: "".
                                classNameContainer="" // Optional.[String].Default: "".
                                customStyleInput={{}} // Optional.[Object].Default: {}.
                                customStyleWrapper={{}} // Optional.[Object].Default: {}.
                                customStyleContainer={{}} // Optional.[Object].Default: {}.
                                onChange={(t_description, e) => {
                                    this.setState({ t_description });
                                }} // Required.[Func].Default: () => {}. Will return the value.
                                onBlur={e => {
                                }}
                                validationOption={{
                                    name: "Trip  Description", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                                    required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                                }}
                            />



              </div>

             

              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_image} id="tg_image" placeholder="Project Cover Image url " 
                  onChange={this.updateState} /> */}

                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "t_image",
                      name: "Trip Image",
                      type: "text",
                      placeholder: "Image URL Please upload image below"
                    }}
                    value={this.state.t_image} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res =>
                      this.setState({ hasImageError: res, validate: false })
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(t_image, e) => {
                      this.setState({ t_image });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Trip Image", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />


              </div>


              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_image} id="tg_image" placeholder="Project Cover Image url " 
                  onChange={this.updateState} /> */}

                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "t_pdf",
                      name: "Trip Itinerary PDF",
                      type: "text",
                      placeholder: "PDF URL Please upload PDF below"
                    }}
                    value={this.state.t_pdf} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res =>
                      this.setState({ hasPDFError: res, validate: false })
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(t_pdf, e) => {
                      this.setState({ t_pdf });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Trip Itinerary PDF", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />


              </div>

              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_image} id="tg_image" placeholder="Project Cover Image url " 
                  onChange={this.updateState} /> */}

                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "t_priceperperson",
                      name: "Trip Price Per Person",
                      type: "text",
                      placeholder: "Price Per Person"
                    }}
                    value={this.state.t_priceperperson} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res =>
                      this.setState({ hasPricePerPersonError: res, validate: false })
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(t_priceperperson, e) => {
                      this.setState({ t_priceperperson });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Price Per Person", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />


              </div>
              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_image} id="tg_image" placeholder="Project Cover Image url " 
                  onChange={this.updateState} /> */}

                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "t_duration",
                      name: "Trip Duration",
                      type: "text",
                      placeholder: "Trip Duration"
                    }}
                    value={this.state.t_duration} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res =>
                      this.setState({ hasDurationError: res, validate: false })
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(t_duration, e) => {
                      this.setState({ t_duration });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Trip Duration", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />


              </div>

              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_image} id="tg_image" placeholder="Project Cover Image url " 
                  onChange={this.updateState} /> */}

                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "t_difficulty",
                      name: "Trip Difficulty",
                      type: "text",
                      placeholder: "Trip Difficulty"
                    }}
                    value={this.state.t_difficulty} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res =>
                      this.setState({ hasDifficultyError: res, validate: false })
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(t_difficulty, e) => {
                      this.setState({ t_difficulty });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Trip Difficulty", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />


              </div>
              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_image} id="tg_image" placeholder="Project Cover Image url " 
                  onChange={this.updateState} /> */}

                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "t_startlocation",
                      name: "Start Location",
                      type: "text",
                      placeholder: "Trip Start Location"
                    }}
                    value={this.state.t_startlocation} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res =>
                      this.setState({ hasStartLocationError: res, validate: false })
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(t_startlocation, e) => {
                      this.setState({ t_startlocation });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Trip Start Location", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />


              </div>

              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_image} id="tg_image" placeholder="Project Cover Image url " 
                  onChange={this.updateState} /> */}

                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "t_endlocation",
                      name: "Trip End Location",
                      type: "text",
                      placeholder: "Trip End Location"
                    }}
                    value={this.state.t_endlocation} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res =>
                      this.setState({ hasEndLocationError: res, validate: false })
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(t_endlocation, e) => {
                      this.setState({ t_endlocation });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Trip End Location", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />


              </div>
              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_image} id="tg_image" placeholder="Project Cover Image url " 
                  onChange={this.updateState} /> */}

                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "t_accomidation",
                      name: "Trip Accomidation",
                      type: "text",
                      placeholder: "Trip Accomidation"
                    }}
                    value={this.state.t_accomidation} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res =>
                      this.setState({ hasAccomidationError: res, validate: false })
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(t_accomidation, e) => {
                      this.setState({ t_accomidation });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Trip Accomidation", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />


              </div>

              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_image} id="tg_image" placeholder="Project Cover Image url " 
                  onChange={this.updateState} /> */}

                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "t_besttimetotravel",
                      name: "Trip Best Time Travel",
                      type: "text",
                      placeholder: "Trip Best Time To Travel"
                    }}
                    value={this.state.t_besttimetotravel} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res =>
                      this.setState({ hasBestTimeToTravelError: res, validate: false })
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(t_besttimetotravel, e) => {
                      this.setState({ t_besttimetotravel });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Trip Best Time To Travel", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />


              </div>
              <div className="form-group">
                {/* <input type="text" className="form-control" value={this.state.tg_image} id="tg_image" placeholder="Project Cover Image url " 
                  onChange={this.updateState} /> */}

                <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "t_activities",
                      name: "Trip Activities",
                      type: "text",
                      placeholder: "Trip Activities"
                    }}
                    value={this.state.t_activities} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    validate={this.state.validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res =>
                      this.setState({ hasActivitiesError: res, validate: false })
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="form-control" // Optional.[String].Default: "".
                    classNameWrapper="" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(t_activities, e) => {
                      this.setState({ t_activities });
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                    }} 
                    validationOption={{
                      name: "Trip Activities", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />


              </div>
              <div className="form-group">

                <select className="form-control" value={this.state.t_tripgroupid} id="t_tripgroupid"
                  onChange={this.updateState}>
                    <option value="empty">SELECT A TRIP GROUP</option>
                  {this.state.tglist &&
                    Object.keys(this.state.tglist).map(id => {
                      let tg = this.state.tglist;
                      return (
                        <option key={id} value={id}>{tg[id]["tripgroup_name"]}</option>
                      )
                    })
                  }
                </select>
                {this.state.tripgrouperror  && (<small className="form-text text-danger" >Please select a Trip Group</small>) }
              </div>
    
              <button onClick={() => this.handleComplete(this.state.t_tripid, this.state.t_name, this.state.t_description, this.state.t_image,this.state.t_pdf,this.state.t_priceperperson,this.state.t_duration,this.state.t_difficulty,this.state.t_startlocation,this.state.t_endlocation,this.state.t_accomidation,
    this.state.t_besttimetotravel,this.state.t_activities,this.state.t_tripgroupid)} className="btn btn-primary btn-submit">Submit</button>

            </div>
            <div className="col-12 mt-5">
              <h5>Upload images here</h5>

              {this.state.isUploading &&
              
                <div className="progress-bar" role="progressbar" style={{ width: this.state.progress + '%' }} aria-valuenow={this.state.progress} aria-valuemin="0" aria-valuemax="100">{this.state.progress}</div>
              
              }
              {this.state.avatarURL &&
                <div className="uploaded image">
                  <label>Image:</label>
                  <a target="_blank" rel="noopener  noreferrer" href={this.state.avatarURL}><img alt="new" className="img img-fluid" src={this.state.avatarURL} /></a>
                </div>
              }
              <FileUploader
                accept="image/*"
                name="avatar"
                randomizeFilename
                storageRef={fbStorage.ref("images")}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              />
            </div>

            <div className="col-12 mt-5">
              <h5>Upload pdf here</h5>

              {this.state.isUploading &&
              
                <div className="progress-bar" role="progressbar" style={{ width: this.state.progress + '%' }} aria-valuenow={this.state.progress} aria-valuemin="0" aria-valuemax="100">{this.state.progress}</div>
              
              }
              {this.state.pdfURL &&
                <div className="uploaded image">
                 Successfully uploaded pdf. The url is automatically assigned to the respective field.
                </div>
              }
              <FileUploader
                accept="pdf/*"
                name="pdf"
                randomizeFilename
                storageRef={fbStorage.ref("pdf")}
                onUploadStart={this.handleUploadStartPdf}
                onUploadError={this.handleUploadErrorPdf}
                onUploadSuccess={this.handleUploadSuccessPdf}
                onProgress={this.handleProgressPdf}
              />
            </div>

                    <div className="col-12 mt-5">

                    <input type="checkbox" onChange={this.showAllTripGroups} checked={this.state.showalltripgroups}/> SHOW ALL TRIP GROUPS

                    <br/>
                        <div className="row">
                        {this.state.showalltripgroups && 
                        
                            Object.keys(this.state.tlist.val()).map(id => {
                              let t = this.state.tlist.val();
                              return (
                                <div className="col-12 mb-3" style={{padding:'10px', border:'1px solid black'}}><span className="tex-left">{t[id]["trip_name"]}</span> 
                                <span style={{float:'right'}}>
                                <button onClick={() => this.deleteTripGroup(id)} className="btn btn-danger">Delete Trip Group</button>
                                  </span></div>
                              )
                            })
                        }
                        </div>
                    </div>


                </div>
            </div>

      </React.Fragment>
    );
  }
};

export default EditTripGroup;