import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {db} from '../firebase';
import WhyUs from './whyus';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import AwesomeSliderStyles from './styles.scss';


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount = () => {

      document.title = "Earthbound Adventures | Leading Adventure-Travel Provider | Unique Treks & Tours";
        /* Bind service to the dropdown*/
        const tg_ref = db.ref("/tripgroup");
        tg_ref.once("value", snapshot => {
           if(snapshot){

            this.setState({tglist :snapshot})
           }
        })

  }

  render() {
    const AutoPlaySlider = withAutoplay(AwesomeSlider);
    return (
      <React.Fragment>
        <div>
          <AutoPlaySlider
         play={true}
         cancelOnInteraction={false}
         interval={6000}
         cssModule={AwesomeSliderStyles}
         bullets={false}
          >
            <div data-src="https://firebasestorage.googleapis.com/v0/b/earthbound-adventures.appspot.com/o/images%2Fac96e572-1a3b-42a5-8cdf-59ca64c4a00e.jpg?alt=media&token=411f02af-25b1-4f08-ba96-7a7bfd0efeb0" >
                <div className='text-center'>
                <h1 className="slide-title">EXPLORE PERU</h1>
                <p className="slide-description">Offering comprehensive travel solutions to individuals and groups</p>
                <Link to="/trips/peru" className="btn slide-btn mt-3">Trips to Peru</Link>
                </div>
                
              </div>
              <div data-src="https://firebasestorage.googleapis.com/v0/b/earthbound-adventures.appspot.com/o/images%2Ff70756c3-9b13-4394-99f0-724e2deb1654.jpg?alt=media&token=95311eee-75b1-4146-b780-29a681e649b7" >
                <div className='text-center'>
                <h1 className="slide-title">EXPLORE BOLIVIA</h1>
                <p className="slide-description">Offering comprehensive travel solutions to individuals and groups</p>
                <Link to="/trips/bolivia" className="btn slide-btn mt-3">Trips to Bolivia</Link>
                </div>
                
              </div>
          
          </AutoPlaySlider>
         
          <div className="container">
            <div className="row homepage-tripgroups mt-5">
              <div className="col-12 mb-3 text-center">
                <h4 className="content-titles">DESTINATIONS</h4>
              </div>

              {this.state.tglist &&
                Object.keys(this.state.tglist.val()).map(id => {
                  let t = this.state.tglist.val();
                  return (
                    <div className="col-md-6  mb-4" key={id}>
                      <Link className="no-text-decoration" to={{ pathname: `/trips/${t[id]["tripgroup_pagename"]}`, tripid: `${id}` }}>
                        <div className="tripgroup-trip-card">
                          <div style={{ backgroundImage: `url(${t[id]["tripgroup_image"]})` }} className="tripgroup-trip-image"></div>
                          <div className="" style={{padding:'15px'}}>
                            <div className="tripgroup-trip-border-top"></div>
                            <div className="tripgroup-trip-description text-center">
                              <h5 className="tripgroup-trip-description-title mb-0"> {t[id]["tripgroup_name"]}</h5>
                              
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })

              }


            </div>
   
          </div>
          <div className="container">
          <WhyUs/>
          
          </div>
        </div>

      </React.Fragment>
    );
  }
};

export default Index;