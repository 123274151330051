import React, {Component}  from "react";
import {db, fbStorage} from '../firebase';
import FileUploader from "react-firebase-file-uploader";

class EditTripGroup extends Component {
  
    constructor(props) {
        super(props);
        
        this.state = {
           data: ''
        }
     };
     

     componentDidMount = () => {
     

        /* Bind service to the dropdown*/
        const tg_ref = db.ref("/tripgroup");
        tg_ref.once("value", snapshot => {
           if(snapshot){

            this.setState({tglist :snapshot})
           }
        })

        
     }

     handleComplete = (tg_name,tg_description, tg_image, tg_tripgroupid) => {

      /* Update the changes made to the trip group */

      db.ref("/tripgroup/"+tg_tripgroupid).update(
           {
             tripgroup_name: tg_name,
             tripgroup_description: tg_description,
             tripgroup_pagename: tg_name.toString().toLowerCase().replace(" ", "-"),
             tripgroup_created_at: Date.now(),
             tripgroup_image: tg_image
           },
           function(error) {
             if (error) {
               alert("Data could not be saved." + error);
             } else {
               alert("Data saved successfully.");
               window.location.reload();
             }
           }
         )
         
        
      }

      

      handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

      handleProgress = progress => this.setState({ progress : progress});

      handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
      };

      handleUploadSuccess = filename => {
        this.setState({ avatar: filename, progress: 100, isUploading: false });
        fbStorage.ref("images").child(filename).getDownloadURL().then(url => this.setState({ avatarURL: url, tg_image: url }));
      };



      tripGroupSelected = (e) => {
        if(e.target.value !== "empty")
        {
          this.setState({tg_tripgroupid: e.target.value})
          const tg_ref = db.ref("/tripgroup/"+e.target.value);
            tg_ref.once("value", snapshot => {
                if (snapshot) {
                  this.setState({
                      tg_name: snapshot.val()["tripgroup_name"], 
                      tg_description: snapshot.val()["tripgroup_description"], 
                      tg_image: snapshot.val()["tripgroup_image"],
                    })
                }
            })
        }
      }

      deleteTripGroup = (id) => {
       
          
          if (window.confirm('Are you sure you want to delete?')) 
          {
              db.ref("/tripgroup/").child(id).remove().then(function() {
                  // File deleted successfully
                  alert("successfully deleted !!")
                  window.location.reload();
                }).catch(function(error) {
                  // Uh-oh, an error occurred!
                  alert(error.message)
                });
          }
          
    
      }

      showAllTripGroups = (e) => {
        this.setState({showalltripgroups: e.target.checked})
      }
      
      updateState = (e) => {
          this.setState({ [e.target.id] : e.target.value  })
      }

  render() {
    return (
    <React.Fragment>
            <div className="container page-content">
                <div className="row">
                    <div className="col-12">
                        <h2>Edit Trip Group</h2>
                    </div>

                    <div className="col-12 my-5">
                        <div className="form-group">

                            <select className="form-control" id="tg_tripgroupid" 
                                onChange={this.tripGroupSelected} value={this.state.tg_tripgroupid}>
                                  <option value="empty">SELECT A TRIP GROUP</option>
                                {this.state.tglist &&
                                    Object.keys(this.state.tglist.val()).map(id => {
                                        let tg = this.state.tglist.val();
                                        return (
                                            <option key={id} value={id}>{tg[id]["tripgroup_name"]}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                    </div>

                    <div className="col-12">
                        <div className="form-group">
                            <input type="text" className="form-control" id="tg_name" placeholder="Enter Trip Group Name"
                            value={this.state.tg_name} onChange={this.updateState} />
                            
                        </div>
                        <div className="form-group">
                            <textarea type="text" className="form-control" id="tg_description" placeholder="Describe the Trip Group" 
                            onChange={this.updateState} value={this.state.tg_description}/>
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" id="tg_image" placeholder="Upload image below" 
                             onChange={this.updateState} value={this.state.tg_image}
                            />
                        </div>
                       
                        
                        <button onClick={() => this.handleComplete(this.state.tg_name,this.state.tg_description,this.state.tg_image,this.state.tg_tripgroupid)} className="btn btn-primary btn-submit">Update Trip Group</button>

                    </div>

                    <div className="col-12 mt-5">
                      <h5>Upload image here</h5>

                        {this.state.isUploading &&
                          <div className="progress-bar" role="progressbar" style={{ width: this.state.progress + '%' }} aria-valuenow={this.state.progress} aria-valuemin="0" aria-valuemax="100">{this.state.progress}</div>
                        }
                        {this.state.avatarURL && 
                        <div className="uploaded image">
                          <label>Image:</label>
                          <a rel="noopener noreferrer" target="_blank" href={this.state.avatarURL}><img alt="" className="img img-fluid"  src={this.state.avatarURL} /></a>
                        </div>
                        }
                        <FileUploader
                          accept="image/*"
                          name="avatar"
                          randomizeFilename
                          storageRef={fbStorage.ref("images")}
                          onUploadStart={this.handleUploadStart}
                          onUploadError={this.handleUploadError}
                          onUploadSuccess={this.handleUploadSuccess}
                          onProgress={this.handleProgress}
                        />
                    </div>

                    <div className="col-12 mt-5">

                    <input type="checkbox" onChange={this.showAllTripGroups} checked={this.state.showalltripgroups}/> SHOW ALL TRIP GROUPS

                    <br/>
                        <div className="row">
                        {this.state.showalltripgroups && 
                        
                            Object.keys(this.state.tglist.val()).map(id => {
                              let tg = this.state.tglist.val();
                              return (
                                <div className="col-12 mb-3" style={{padding:'10px', border:'1px solid black'}}><span className="tex-left">{tg[id]["tripgroup_name"]}</span> 
                                <span style={{float:'right'}}>
                                <button onClick={() => this.deleteTripGroup(id)} className="btn btn-danger">Delete Trip Group</button>
                                  </span></div>
                              )
                            })
                        }
                        </div>
                    </div>


                </div>
            </div>

      </React.Fragment>
    );
  }
};

export default EditTripGroup;