import React, {Component}  from "react";
import {Link, NavLink} from 'react-router-dom';


class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showNav: false
        }
     }

     componentDidMount = () =>{

     }
    showHamburgerMenu = (status) => {
      this.setState({showNav: status});
    }

  render() {
    return (
    <React.Fragment>
             <nav className=" navbar navbar-expand-md navbar-light fixed-top">
      <div className="container nav-container">
        <Link className="brand" to="/">
         <img src="https://firebasestorage.googleapis.com/v0/b/earthbound-adventures.appspot.com/o/images%2F013e75d3-09f6-4b5e-a3cd-6ecd30eb602a.png?alt=media&token=5dff994d-d30c-47f3-9dbe-9ae72d5e8dca"
          className="logo"
          alt="earthbound adventures"
          ></img>
         <h5 className="logo-text ml-3 d-none d-lg-inline-block">Earthbound Adventures</h5>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => this.showHamburgerMenu(true)}
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className={this.state.showNav ? 'collapse navbar-collapse show': 'collapse navbar-collapse'} id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item ">
              <NavLink className="nav-link" onClick={() => this.showHamburgerMenu(false)} activeClassName="nav-link-selected" exact to="/index">
                HOME
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link" onClick={() => this.showHamburgerMenu(false)} activeClassName="nav-link-selected" exact to="/about-us">
                ABOUT US
              </NavLink>
            </li>
            
            <li className="nav-item">
              <NavLink className="nav-link" onClick={() => this.showHamburgerMenu(false)} activeClassName="nav-link-selected" to="/trips/peru">
                PERU
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link" onClick={() => this.showHamburgerMenu(false)} activeClassName="nav-link-selected" to="/trips/bolivia">
                BOLIVIA
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link" onClick={() => this.showHamburgerMenu(false)} activeClassName="nav-link-selected" to="/contact">
                CONTACT
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>

      </React.Fragment>
    );
  }
};

export default Navbar;