import React, { Component } from "react";
import { db } from '../firebase';

class ImagesByTrip extends Component {

    constructor(props) {
        super(props);

        this.state = {
            validate: false,

            hasNameError: false,
            hasDescriptionError: false,
            hasImageError: false,
            hasPricePerPersonError: false,
            hasDurationError: false,
            hasDifficultyError: false,
            hasStartLocationError: false,
            hasEndLocationError: false,
            hasAccomidationError: false,
            hasBestTimeToTravelError: false,
            hasActivitiesError: false,
            hasPDFError: false,
            tripgrouperror: false
        }
    };


    componentDidMount = () => {


        /* Bind service to the dropdown*/
        const t_ref = db.ref("/trip");
        t_ref.once("value", snapshot => {
            if (snapshot) {

                this.setState({ tlist: snapshot })
            }
        })

    }





    tripSelected = (e) => {
        if (e.target.value !== "empty") {
            this.setState({ t_tripname: e.target.value })
            let tripsRef = db.ref("/tripimages");
            tripsRef.orderByChild("image_tripid").equalTo(e.target.value).once("value", snapshot => {
                if (snapshot.val()) {
                    this.setState({ ilist: snapshot });
                } else {
                    this.setState({ ilist: null })
                }
            })
        }
    }

    deleteTripGroup = (id) => {


        if (window.confirm('Are you sure you want to delete?')) {
            db.ref("/tripimages/").child(id).remove().then(function () {
                // File deleted successfully
                alert("successfully deleted !!")
                window.location.reload();
            }).catch(function (error) {
                // Uh-oh, an error occurred!
                alert(error.message)
            });
        }


    }

    showAllTripGroups = (e) => {
        this.setState({ showalltripgroups: e.target.checked })
    }

    updateState = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }

    render() {
        return (
            <React.Fragment>
                <div className="container page-content">
                    <div className="row">
                        <div className="col-12">
                            <h2>Edit Trip</h2>
                        </div>

                        <div className="col-12 my-5">
                            <div className="form-group">

                                <select className="form-control" id="t_tripname"
                                    onChange={this.tripSelected} value={this.state.t_tripname}>
                                    <option value="empty">SELECT A TRIP</option>
                                    {this.state.tlist &&
                                        Object.keys(this.state.tlist.val()).map(id => {
                                            let t = this.state.tlist.val();
                                            return (
                                                <option key={id} value={id}>{t[id]["trip_name"]}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>

                        </div>




                        <div className="col-12 ">
                            {this.state.ilist ? (
                                <div> <input type="checkbox" onChange={this.showAllTripGroups} checked={this.state.showalltripgroups} /> SHOW ALL TRIP GROUPS</div>

                            ) : null
                            }

                            <br />
                            <div className="row">
                                {this.state.showalltripgroups &&

                                    Object.keys(this.state.ilist.val()).map(id => {
                                        let i = this.state.ilist.val();
                                        return (
                                            <div key={id} className="col-12 mb-3" style={{ padding: '10px', border: '1px solid black' }}>
                                                <a href={i[id]["image_tripimage"]} target="_blank" rel="noopener noreferrer"><div className="d-inline-block" style={{ height: '150px', width: '100%', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${i[id]["image_tripimage"]})` }}></div>
                                                </a>
                                                <span style={{ float: 'right' }}>
                                                    <button onClick={() => this.deleteTripGroup(id)} className="btn btn-danger">Delete Trip Group</button>
                                                </span></div>
                                        )
                                    })
                                }
                            </div>
                        </div>


                    </div>
                </div>

            </React.Fragment>
        );
    }
};

export default ImagesByTrip;