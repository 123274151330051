import React, {Component}  from "react";


class TripGroups extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
     }
  render() {
    return (
    <React.Fragment>
      

      </React.Fragment>
    );
  }
};

export default TripGroups;